import {FC} from 'react'

const TermOfUse: FC = () => {
  return (
    <>
      <div style={{ margin: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', textAlign: 'left' }}>
        <h1 style={{ textAlign: 'left' }}>Termo de Uso Plataforma EZMessage</h1>

        <p><strong>1. Aceitação dos Termos</strong></p>
        <p>Ao acessar e utilizar nossa plataforma de comunicação via WhatsApp, você concorda em cumprir e estar vinculado aos seguintes Termos de Uso. Se você não concorda com qualquer parte destes termos, não use nossos serviços.</p>

        <p><strong>2. Descrição do Serviço</strong></p>
        <p>Nossa plataforma oferece um serviço de envio de mensagens em lote via WhatsApp, com funcionalidades de automação de atendimentos, interface simplificada, suporte para múltiplos atendentes, e monitoramento de campanhas.</p>

        <p><strong>3. Uso da Plataforma</strong></p>
        <p><strong>Conta do Usuário:</strong> Para acessar nossos serviços, você pode ser obrigado a fornecer informações pessoais e criar uma conta. Você é responsável por manter a confidencialidade de sua conta e senha.</p>
        <p><strong>Uso Permitido:</strong> Você concorda em usar a plataforma apenas para fins legais e de acordo com os Termos de Uso.</p>
        <p><strong>Proibições:</strong> É proibido usar a plataforma para enviar mensagens não solicitadas (spam), conteúdos ilegais, abusivos ou que violem direitos de terceiros.</p>

        <p><strong>4. Planos e Pagamentos</strong></p>
        <p><strong>Planos:</strong> Oferecemos diferentes planos de assinatura, incluindo um teste gratuito de 3 dias. Após o período de teste, você será cobrado de acordo com o plano escolhido.</p>
        <p><strong>Pagamentos:</strong> Os pagamentos são mensais e realizados de forma antecipada. Você concorda em pagar todas as taxas aplicáveis associadas ao uso da plataforma.</p>

        <p><strong>5. Cancelamento e Reembolso</strong></p>
        <p><strong>Cancelamento:</strong> Você pode cancelar sua assinatura a qualquer momento. O cancelamento entrará em vigor no próximo ciclo de faturamento.</p>
        <p><strong>Reembolso:</strong> Não oferecemos reembolso por períodos não utilizados após o cancelamento.</p>

        <p><strong>6. Propriedade Intelectual</strong></p>
        <p>Todos os direitos, títulos e interesses relativos à plataforma e aos seus conteúdos são de nossa propriedade ou de nossos licenciadores. Você concorda em não reproduzir, distribuir ou criar trabalhos derivados sem nossa autorização expressa.</p>

        <p><strong>7. Privacidade</strong></p>
        <p>Nossa Política de Privacidade descreve como coletamos, usamos e compartilhamos suas informações pessoais. Ao usar a plataforma, você concorda com a coleta e uso de informações conforme descrito em nossa Política de Privacidade.</p>

        <p><strong>8. Limitação de Responsabilidade</strong></p>
        <p>A plataforma é fornecida "como está" e "conforme disponível". Não garantimos que a plataforma será ininterrupta ou livre de erros. Em nenhuma circunstância seremos responsáveis por quaisquer danos indiretos, incidentais, ou consequentes decorrentes do uso ou da incapacidade de usar a plataforma.</p>

        <p><strong>9. Alterações nos Termos</strong></p>
        <p>Podemos modificar estes Termos de Uso a qualquer momento. Quaisquer alterações serão publicadas em nosso site e entrarão em vigor imediatamente após a publicação. Seu uso continuado da plataforma após a publicação de alterações constitui sua aceitação dos novos termos.</p>

        <p><strong>10. Contato</strong></p>
        <p>Se você tiver alguma dúvida sobre estes Termos de Uso, entre em contato conosco pelo e-mail contato@mooblab.com.</p>
      </div>
    </>
  )
}

export {TermOfUse}
