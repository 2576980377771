import {useQuery} from 'react-query'
import {EditModalForm} from './EditModalForm'
import {isNotEmpty, QUERIES} from '../../../../../../_metronic/helpers'
import {useListView} from '../core/ListViewProvider'
import {getFilter} from '../core/_requests'
import { NewsStatus } from '../core/_models'

const EditModalFormWrapper = () => {
  const {itemIdForUpdate, setItemIdForUpdate} = useListView()
  const enabledQuery: boolean = isNotEmpty(itemIdForUpdate)

  const {
    isLoading,
    data: documents,
    error,
    status
  } = useQuery(
    `${QUERIES.DOCUMENTS_LIST}-documents-${itemIdForUpdate}`,
    () => {
      return getFilter({id: itemIdForUpdate, take: 0, skip: 1})
    },
    {
      cacheTime: 0,
      refetchOnWindowFocus: false,
      enabled: enabledQuery,
      onError: (err) => {
        setItemIdForUpdate(undefined)
        console.error(err)
      },
    }
  )

  if (!itemIdForUpdate) {
    return (
      <EditModalForm
      isLoading={isLoading}
        response={{
          status_id: NewsStatus.nao_enviado.id,
          created_at: new Date(),
        }}
        status={status}
      />
    )
  }

  if (!isLoading && !error && documents) {
    return (
      <EditModalForm
      isLoading={isLoading}
        response={documents?.data}
        status={status}
      />
    )
  }

  return null
}

export {EditModalFormWrapper}
