export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname

export function avatarPath(avatarUrl: string | undefined, avatarFile: string | undefined) {
  if (!avatarUrl || !avatarFile) return '/media/icons/duotune/general/gen046.svg'
  const url = new URL(avatarUrl || '')
  const final = new URL(url.pathname + '/' + avatarFile || '', avatarUrl)
  return new URL(final).href
}

export function imageCoursePath(urlFile: string | undefined, imageFile: string | undefined) {
  if (!urlFile || !imageFile) return '/media/icons/duotune/general/gen046.svg'
  const url = new URL(urlFile || '')
  const final = new URL(url.pathname + '/' + imageFile || '', urlFile)
  return new URL(final).href
}

export function imageMeetingsPath(urlFile: string | undefined, imageFile: string | undefined) {
  if (!urlFile || !imageFile) return '/media/icons/duotune/general/gen046.svg'
  const url = new URL(urlFile || '')
  const final = new URL(url.pathname + '/' + imageFile || '', urlFile)
  return new URL(final).href
}
