/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom'
import {PrivacyLayout} from './PrivacyLayout'
import { TermOfUse } from './components/TermOfUse'
import { Policy } from './components/Policy'

const PrivacyPage = () => (
  <Routes>
    <Route element={<PrivacyLayout />}>
      <Route path='terms' element={<TermOfUse />} />
      <Route path='policy' element={<Policy />} />
    </Route>
  </Routes>
)

export {PrivacyPage}
