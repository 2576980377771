import { useQuery } from "react-query"
import { GETCardCampaignsAndMessagesData } from "../core/_requests"
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  className: string
}

const MessagesCard = ({ className }: Props) => {
  const {currentUser} = useAuth();
  const company_id = (currentUser?.company_id === undefined) ? 0 : currentUser?.company_id;
  
  const { data, isLoading } = useQuery(
    'users-card-report',
    () => {
      return GETCardCampaignsAndMessagesData(company_id)
    }, {
    cacheTime: 0,
    refetchOnWindowFocus: false
  })

  return (
    <div
      className={`card  card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        height: '100%'
      }}
    >
      <div className='card-header pt-5' style={{ gap: 20 }}>
        <div className='card-title d-flex flex-column'>
          {isLoading ? (
            <>
              <span className='fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2 bg-white rounded animation-breathe'>
                <span style={{ opacity: 0 }}>
                  0000
                </span>
              </span>
              <span className='text-gray-800 mt-3 fw-semibold fs-6 bg-white rounded animation-breathe'>mensagens enviadas</span>
            </>
          ) : (
            <>
              <span className='fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2'>{data?.total_messages}</span>
              <span className='text-gray-800 opacity-75 pt-1 fw-semibold fs-6'>Mensagens enviadas</span>
            </>
          )}
        </div>
        {/* <div className='card-title d-flex flex-column' style={{ marginRight: 'auto' }}>
          {isLoading ? (
            <>
              <span className='fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2 bg-white rounded animation-breathe'>
                <span style={{ opacity: 0 }}>
                  00
                </span>
              </span>
              <span className='text-gray-800 mt-3 fw-semibold fs-6 bg-white rounded animation-breathe'>
                <span style={{ opacity: 0 }}>
                  Cursos
                </span>
              </span>
            </>
          ) : (
            <>
              <span className='fs-2hx fw-bold text-gray-800 me-2 lh-1 ls-n2'>{data?.total_courses}</span>
              <span className='text-gray-800 opacity-75 pt-1 fw-semibold fs-6'>Dias</span>
            </>
          )}
        </div> */}
      </div>
    </div >
  )
}
export { MessagesCard }
