import { useQuery } from "react-query"
import { GETCardCampaignsAndMessagesData } from "../core/_requests"
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  className: string
  description: string
  color: string
  img: string
}

const CampaignsCard = ({ className, description, color, img }: Props) => {
  const {currentUser} = useAuth();
  const company_id = (currentUser?.company_id === undefined) ? 0 : currentUser?.company_id;

  const { data, isLoading } = useQuery(
    'users-card-report',
    () => {
      return GETCardCampaignsAndMessagesData(company_id)
    }, {
    cacheTime: 0,
    refetchOnWindowFocus: false
  })

  return (
    <div
      className={`card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end ${className}`}
      style={{
        backgroundColor: color,
        backgroundImage: `url('${img}')`,
        height: '100%'
      }}
    >
      <div className='card-header pt-5' style={{ gap: 20 }}>
        <div className='card-title d-flex flex-column'>
          {isLoading ? (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2 bg-white rounded animation-breathe'>00</span>
              <span className='text-white mt-3 fw-semibold fs-6 bg-white rounded animation-breathe'>{description}</span>
            </>
          ) : (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{data?.total_campaigns}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>{description}</span>
            </>
          )}
        </div>
        
        {/* <div className='card-title d-flex flex-column' style={{ marginRight: 'auto' }}>
          {isLoading ? (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2 bg-white rounded animation-breathe'>00</span>
              <span className='text-white mt-3 fw-semibold fs-6 bg-white rounded animation-breathe'>Mensagens</span>
            </>
          ) : (
            <>
              <span className='fs-2hx fw-bold text-white me-2 lh-1 ls-n2'>{data?.total_childrens}</span>
              <span className='text-white opacity-75 pt-1 fw-semibold fs-6'>Mensagens</span>
            </>
          )}
        </div> */}
        
      </div>
    </div >
  )
}
export { CampaignsCard }
