import axios from 'axios'
import {AuthModel, UserModel, IGetUserByFilter} from './_models'

const API_URL = process.env.REACT_APP_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/admin/users/filter`
export const LOGIN_URL = `${API_URL}/admin/auth`
export const REGISTER_URL = 'https://automacao.mooblab.com/webhook/ez-message-create-company'
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`

// Server should return AuthModel
export function login(email: string, password: string) {
  const userlogged = axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
  return userlogged;
}

// Server should return AuthModel
export function register(user: any) {
  return axios.post(REGISTER_URL, user)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByFilter(filter: IGetUserByFilter) {
  return axios.get<UserModel[]>(GET_USER_BY_ACCESSTOKEN_URL, {
    params: filter,
  })
}
