/* eslint-disable react/jsx-no-target-blank */
import {SidebarMenuItem} from './SidebarMenuItem'
import { SidebarMenuItemExternal } from './SidebarMenuItemExternal'

const SidebarMenuMain = () => {

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/graphs/gra012.svg'
        title='Painel'
        fontIcon='bi-graph-up-arrow'
      />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Sistemas</span>
        </div>
      </div>
      <SidebarMenuItem
        to='/apps/campaigns-management'
        title='Gerenciar Campanhas'
        fontIcon='bi-folder2-open'
        icon='/media/icons/duotune/abstract/abs027.svg'
      />
      <SidebarMenuItemExternal
        target='_blank'
        to='https://atendimento.mooblab.com'
        title='Gerenciar Atendimentos'
        fontIcon='bi-folder2-open'
        icon='/media/icons/duotune/abstract/abs027.svg'
      />
    </>
  )
}

export {SidebarMenuMain}
