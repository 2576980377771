/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {KTSVG} from '../../../../../../../_metronic/helpers'
import {useListView} from '../../core/ListViewProvider'
import {AlertModal} from '../../../../../../components/AlertModal'
import { processingCampaign } from '../../core/_requests'
import { toast } from 'react-toastify'
import { Campaign } from '../../core/_models'

type Props = {
  campaign: Campaign
}

const DocumentsActionsCell: FC<Props> = ({campaign}) => {
  const {setItemIdForUpdate} = useListView()
  const [showModalStartCampaign, setShowModalNewPassword] = useState(false)
  

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const openEditModal = () => {
    setItemIdForUpdate(campaign.id)
  }

  const dispatchCampaign = () => {
    const payload = {
      name: campaign.name,
      message: campaign.message,
      company_id: campaign.company_id,
      campaign_id: campaign.id,
      attachment_url: 'https://ezmessage.s3.amazonaws.com/customers-uploads/' + campaign.attachment_url,
      contacts_url: 'https://ezmessage.s3.amazonaws.com/customers-uploads/' + campaign.contacs_url
    };

    processingCampaign(payload).then((response) => {
      if (response?.data?.success) {
        toast.success('Envio concluído com sucesso!')
      } else {
        toast.error("Falha ao enviar campanha")
      }
    })

    setShowModalNewPassword(false)
  }

  const handleProcessingCampaign = () => {
    setShowModalNewPassword(!showModalStartCampaign)
  }

  return (
    <>
      <a
        href='#'
        className='btn btn-light btn-active-light-primary btn-sm'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
      >
        Ações
        <KTSVG path='/media/icons/duotune/arrows/arr072.svg' className='svg-icon-5 m-0' />
      </a>
      {/* begin::Menu */}
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold fs-7 w-150px py-4'
        data-kt-menu='true'
      >
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a className='menu-link px-3' onClick={openEditModal}>
            Editar
          </a>
        </div>
        {/* end::Menu item */}
        
        {/* begin::Menu item */}
        <div className='menu-item px-3'>
          <a
            className='menu-link px-3'
            data-kt-users-table-filter='activate_row'
            onClick={handleProcessingCampaign}
          >
            Enviar Campanha
          </a>
        </div>
        <AlertModal
        id='handleProcessingCampaign'
        show={showModalStartCampaign}
        title='Confirma o envio da campanha?'
        buttonConfirm={{ fnCallback: dispatchCampaign }}
        buttonCancel={{ fnCallback: () => setShowModalNewPassword(false) }}
      >
        ATENÇÃO<br/><br/>Uma vez que for iniciada, não será possível interromper o envio das mensagens.
        
      </AlertModal>
        {/* end::Menu item */}

      </div>
      {/* end::Menu */}
    </>
  )
}

export {DocumentsActionsCell}
