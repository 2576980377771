import {FC} from 'react'

const Policy: FC = () => {
  return (
    
    <>
      <div style={{ margin: '20px', fontFamily: 'Arial, sans-serif', lineHeight: '1.6', textAlign: 'left' }}>
      <h1 style={{ textAlign: 'left' }}>Política de Privacidade plataforma EZMessage</h1>

      <p><strong>Última atualização:</strong> 01/06/2024</p>

      <p><strong>1. Introdução</strong></p>
      <p>Esta Política de Privacidade descreve como coletamos, usamos, divulgamos e protegemos suas informações quando você utiliza nossa plataforma de comunicação via WhatsApp. Ao acessar ou usar nossos serviços, você concorda com os termos desta política.</p>

      <p><strong>2. Informações que Coletamos</strong></p>
      <p><strong>2.1 Informações Pessoais:</strong></p>
      <ul>
        <li>Nome</li>
        <li>Endereço de e-mail</li>
        <li>Número de telefone</li>
        <li>Informações de pagamento</li>
      </ul>

      <p><strong>2.2 Informações de Uso:</strong></p>
      <ul>
        <li>Endereço IP</li>
        <li>Tipo de navegador</li>
        <li>Páginas visitadas</li>
        <li>Tempo gasto nas páginas</li>
        <li>Dados de interação com a plataforma</li>
      </ul>

      <p><strong>3. Como Usamos Suas Informações</strong></p>
      <p>Utilizamos as informações coletadas para:</p>
      <ul>
        <li>Fornecer e manter nossos serviços</li>
        <li>Processar transações e gerenciar assinaturas</li>
        <li>Melhorar e personalizar a experiência do usuário</li>
        <li>Enviar comunicações promocionais e informativas (com seu consentimento)</li>
        <li>Monitorar e analisar o uso da plataforma</li>
        <li>Detectar, prevenir e solucionar problemas técnicos</li>
      </ul>

      <p><strong>4. Compartilhamento de Informações</strong></p>
      <p>Não vendemos, trocamos ou transferimos suas informações pessoais para terceiros sem o seu consentimento, exceto nos seguintes casos:</p>
      <ul>
        <li>Provedores de serviços que nos auxiliam na operação da plataforma</li>
        <li>Cumprimento de obrigações legais</li>
        <li>Proteção de direitos, propriedade ou segurança da nossa empresa, usuários e outros</li>
      </ul>

      <p><strong>5. Segurança das Informações</strong></p>
      <p>Adotamos medidas de segurança apropriadas para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição. No entanto, nenhum método de transmissão pela Internet ou armazenamento eletrônico é 100% seguro, portanto, não podemos garantir segurança absoluta.</p>

      <p><strong>6. Retenção de Dados</strong></p>
      <p>Manteremos suas informações pessoais pelo tempo necessário para cumprir os propósitos descritos nesta Política de Privacidade, a menos que um período de retenção mais longo seja exigido ou permitido por lei.</p>

      <p><strong>7. Seus Direitos</strong></p>
      <p>Você tem o direito de:</p>
      <ul>
        <li>Acessar suas informações pessoais que coletamos</li>
        <li>Corrigir quaisquer informações pessoais imprecisas</li>
        <li>Solicitar a exclusão de suas informações pessoais</li>
        <li>Optar por não receber comunicações promocionais</li>
      </ul>
      <p>Para exercer seus direitos, entre em contato conosco pelo e-mail contato@mooblab.com.</p>

      <p><strong>8. Cookies e Tecnologias Semelhantes</strong></p>
      <p>Utilizamos cookies e tecnologias semelhantes para coletar informações sobre suas interações com nossa plataforma e melhorar sua experiência. Você pode configurar seu navegador para recusar cookies, mas isso pode limitar algumas funcionalidades da plataforma.</p>

      <p><strong>9. Alterações a Esta Política de Privacidade</strong></p>
      <p>Podemos atualizar esta Política de Privacidade periodicamente. Publicaremos a nova política no nosso site e informaremos você sobre quaisquer alterações significativas. O uso continuado da plataforma após a publicação de alterações constitui sua aceitação da política revisada.</p>

      <p><strong>10. Contato</strong></p>
      <p>Se você tiver dúvidas ou preocupações sobre esta Política de Privacidade, entre em contato conosco pelo e-mail contato@mooblab.com.</p>
    </div>
    </>
  )
}

export {Policy}
