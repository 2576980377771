import {ID, Response} from '../../../../../../_metronic/helpers'

export const NewsStatus = {
  'nao_enviado': {id: 3, value: 'não enviado', name: 'Não enviado'},
  'enviado': {id: 4, value: 'enviado', name: 'Enviado'}
}

export type Campaign = {
  id: number;
  name: string;
  message: string;
  attachment_url: string;
  contacs_url: string;
  status_id: number;
  status_name: string;
  company_id: number;
  company_name: string;
  created_at?: string;
}

export type Status = {
  id?: ID
  name?: string
}


export type QueryResponse = Response<Array<Campaign>>

export const initialNews: Campaign = {
  id: 0,
  name: '',
  message: '',
  attachment_url: '',
  contacs_url: '',
  status_id: 3,
  status_name: '',
  company_id: 0,
  company_name: ''
}

export type ImageUploadResponse = Response<{file_name: string}>
export type CampaignResponse = Response<{success: boolean}>
