import axios, {AxiosResponse} from 'axios'
import {Response} from '../../../../../../_metronic/helpers'
import {
  Campaign,
  QueryResponse,
  ImageUploadResponse,
  CampaignResponse,
} from './_models'

const API_URL = process.env.REACT_APP_API_URL

const getFilter = (query: any): Promise<QueryResponse> => {
  return axios
    .get(`${API_URL}/admin/campaings/filter`, {params: query})
    .then((d: AxiosResponse<QueryResponse>) => d.data)
}

const create = (campaign: Campaign, company_id: number): Promise<Campaign | undefined> => {
  return axios
    .post(`${API_URL}/admin/campaings/create/${company_id}`, campaign)
    .then((response: AxiosResponse<Response<Campaign>>) => response.data)
    .then((response: Response<Campaign>) => response.data)
}

const update = (meetings: Campaign): Promise<Campaign | undefined> => {
  return axios
    .put(`${API_URL}/admin/campaings/update/${meetings.id}`, meetings)
    .then((response: AxiosResponse<Response<Campaign>>) => {
      return response.data
    })
    .then((response: Response<Campaign>) => response.data)
}

const uploadFile = (file: File): Promise<ImageUploadResponse | undefined> => {
  const formData = new FormData()
  formData.append('image', file)
  return axios.patch(`${API_URL}/admin/campaings/upload`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

const processingCampaign = (payload: any): Promise<CampaignResponse | undefined> => {
  return axios
    .post('https://automacao.mooblab.com/webhook/campanha-whatsapp-admin-imagem', payload)
    .then((response: Response<CampaignResponse>) => {
      return response.data
    })
}

export {
  create,
  update,
  getFilter,
  uploadFile,
  processingCampaign
}
