import axios from "axios"
import {
  DashboarCampaignsAndMessagesCard,
  DashboarMessagesByCampaignsCard,
} from "./_models"
// import {useAuth} from '../../../../app/modules/auth'

const API_URL = process.env.REACT_APP_API_URL

export const GETCardCampaignsAndMessagesData = async (company_id: number) => {
  return axios.get<DashboarCampaignsAndMessagesCard>(`${API_URL}/admin/dashboard/card1/${company_id}
  `).then(response => response.data)
}

export const GETCardMessagesByCampaignsData = async (company_id: number) => {
  return axios.get<DashboarMessagesByCampaignsCard[]>(`${API_URL}/admin/dashboard/card2/${company_id}
  `).then(response => response.data)
}