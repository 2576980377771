/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {login, register} from '../core/_requests'
import {Link} from 'react-router-dom'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import MaskedInput from 'react-input-mask'
/*import {UserModel} from '../core/_models'*/

const initialValues = {
  name: '',
  email: '',
  phone: '',
  company_name: '',
  document_number: '',
  day_of_birth: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  name: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(255, 'Máximo 255 caracteres')
    .required('Nome é obrigatório'),
  email: Yup.string()
    .email('Email inválido')
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Email é obrigatório'),
  phone: Yup.string()
    .required('Whatsapp é obrigatório'),
  company_name: Yup.string()
    .min(3, 'Mínimo 5 caracteres')
    .max(255, 'Máximo 50 caracteres')
    .required('Nome de exibição é obrigatório'),
  document_number: Yup.string()
    .min(11, 'Mínimo 11 caracteres')
    .required('CPF é obrigatório'),
  day_of_birth: Yup.string()
  .min(8, 'Mínimo 8 caracteres')
  .required('Data de Nascimento é obrigatória'),
  password: Yup.string()
    .min(3, 'Mínimo 3 caracteres')
    .max(50, 'Máximo 50 caracteres')
    .required('Senha é obrigatória'),
  changepassword: Yup.string()
    .required('Confirmação da senha é obrigatória')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], 'Senha não confere'),
    }),
  acceptTerms: Yup.bool().required('Você deve aceitar os termos de uso'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {

        const new_user = {
          user: {
            name: values.name,
            email: values.email,
            password: values.password,
            phone: values.phone,
            document_number: values.document_number,
            day_of_birth: values.day_of_birth,
          },
          company: {
            name: values.company_name,
            state: "",
            city: "",
            neighborhood: "",
            address: "",
            document_number: values.document_number
          }
        }

        await register(new_user)
        const { data: auth } = await login(values.email, values.password)
        saveAuth(auth)
        setCurrentUser(auth.user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('Os dados cadastrais estão incorretos')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Cadastre-se</h1>
        {/* end::Title */}
      </div>
      {/* end::Heading */}

      {formik.status && (
        <div className='mb-lg-15 alert alert-danger'>
          <div className='alert-text font-weight-bold'>{formik.status}</div>
        </div>
      )}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Nome</label>
        <input
          placeholder='Nome'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.name && formik.errors.name,
            },
            {
              'is-valid': formik.touched.name && !formik.errors.name,
            }
          )}
        />
        {formik.touched.name && formik.errors.name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Email */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Email</label>
        <input
          placeholder='Email'
          type='email'
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}
      
      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Whatsapp</label>
        <MaskedInput
                mask={"(99) 99999-9999"}
                placeholder='Número Whatsapp'
                {...formik.getFieldProps('phone')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.phone && formik.errors.phone,
                  },
                  {
                    'is-valid': formik.touched.phone && !formik.errors.phone,
                  }
                )}
                name='phone'
                autoComplete='off'
              />
        {formik.touched.phone && formik.errors.phone && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.phone}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Nome de Exibição no Whatsapp</label>
        <input
          placeholder='Nome que será exibido no whatsapp'
          type='text'
          autoComplete='off'
          {...formik.getFieldProps('company_name')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.company_name && formik.errors.company_name,
            },
            {
              'is-valid': formik.touched.company_name && !formik.errors.company_name,
            }
          )}
        />
        {formik.touched.company_name && formik.errors.company_name && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.company_name}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>CPF</label>
        <MaskedInput
                mask={"999.999.999-99"}
                placeholder='Número do CPF'
                {...formik.getFieldProps('document_number')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.document_number && formik.errors.document_number,
                  },
                  {
                    'is-valid': formik.touched.document_number && !formik.errors.document_number,
                  }
                )}
                name='document_number'
                autoComplete='off'
              />
        {formik.touched.document_number && formik.errors.document_number && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.document_number}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Firstname */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-dark fs-6'>Data de Nascimento</label>
        <MaskedInput
                mask={"99/99/9999"}
                placeholder='Data de Nascimento'
                {...formik.getFieldProps('day_of_birth')}
                className={clsx(
                  'form-control bg-transparent',
                  {
                    'is-invalid': formik.touched.day_of_birth && formik.errors.day_of_birth,
                  },
                  {
                    'is-valid': formik.touched.day_of_birth && !formik.errors.day_of_birth,
                  }
                )}
                name='day_of_birth'
                autoComplete='off'
              />
        {formik.touched.day_of_birth && formik.errors.day_of_birth && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.day_of_birth}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group Password */}
      <div className='fv-row mb-8' data-kt-password-meter='true'>
        <div className='mb-1'>
          <label className='form-label fw-bolder text-dark fs-6'>Senha</label>
          <div className='position-relative mb-3'>
            <input
              type='password'
              placeholder='Senha'
              autoComplete='off'
              {...formik.getFieldProps('password')}
              className={clsx(
                'form-control bg-transparent',
                {
                  'is-invalid': formik.touched.password && formik.errors.password,
                },
                {
                  'is-valid': formik.touched.password && !formik.errors.password,
                }
              )}
            />
            {formik.touched.password && formik.errors.password && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.password}</span>
                </div>
              </div>
            )}
          </div>
          {/* begin::Meter */}
          <div
            className='d-flex align-items-center mb-3'
            data-kt-password-meter-control='highlight'
          >
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
            <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
          </div>
          {/* end::Meter */}
        </div>
        <div className='text-muted'>
          Use 8 ou mais caracteres combinando letras, números & símbolos.
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group Confirm password */}
      <div className='fv-row mb-5'>
        <label className='form-label fw-bolder text-dark fs-6'>Confirme a Senha</label>
        <input
          type='password'
          placeholder='Confirmação de Senha'
          autoComplete='off'
          {...formik.getFieldProps('changepassword')}
          className={clsx(
            'form-control bg-transparent',
            {
              'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
            },
            {
              'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
            }
          )}
        />
        {formik.touched.changepassword && formik.errors.changepassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.changepassword}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-check form-check-inline' htmlFor='kt_login_toc_agree'>
          <input
            className='form-check-input'
            type='checkbox'
            id='kt_login_toc_agree'
            {...formik.getFieldProps('acceptTerms')}
          />
          <span>
            Eu aceito os{' '}
            <a
              href='https://app.ezmessage.com.br/privacy/terms'
              target='_blank'
              className='ms-1 link-primary'
            >
              Termos de uso
            </a>
            .
          </span>
        </label>
        {formik.touched.acceptTerms && formik.errors.acceptTerms && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.acceptTerms}</span>
            </div>
          </div>
        )}
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='text-center'>
        <button
          type='submit'
          id='kt_sign_up_submit'
          className='btn btn-lg btn-primary w-100 mb-5'
          disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
        >
          {!loading && <span className='indicator-label'>Enviar</span>}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Por favor, aguarde...{' '}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_signup_form_cancel_button'
            className='btn btn-lg btn-light-primary w-100 mb-5'
          >
            Cancelar
          </button>
        </Link>
      </div>
      {/* end::Form group */}
    </form>
  )
}
