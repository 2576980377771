/* eslint-disable jsx-a11y/anchor-is-valid */
import ApexCharts, { ApexOptions } from 'apexcharts'
import { useCallback, useEffect, useRef } from 'react'
import { useQuery } from 'react-query'
import { GETCardMessagesByCampaignsData } from '../core/_requests'
import { DashboarMessagesByCampaignsCard } from '../core/_models'
import {useAuth} from '../../../../app/modules/auth'

type Props = {
  className: string
}

function getChartOptions(dataList: DashboarMessagesByCampaignsCard[] = []): ApexOptions {
  

  return {
    series: [{
      data: dataList.map(item => ({ x: item.campaignName, y: Number((Number(item.total_messages)).toFixed(2)) }))
    }],
    legend: {
      show: false
    },
    chart: {
      height: 350,
      width: '100%',
      type: 'treemap'
    },
    // colors: ["#f1416c"],
    title: {
      text: 'Mensagens enviadas por Campanha',
      align: 'center',
      style: {
        color: 'gray',
        fontSize: '20px',
      }
    },
    
    plotOptions: {
      treemap: {
        distributed: true,
        enableShades: false,

      }
    },
  }
}

const EngageChart = ({ className }: Props) => {
  const {currentUser} = useAuth();
  const chartRef = useRef<HTMLDivElement | null>(null)
  const company_id = (currentUser?.company_id === undefined) ? 0 : currentUser?.company_id;

  const { data, isLoading } = useQuery(
    'watched-videos-by-company-chart',
    () => {
      return GETCardMessagesByCampaignsData(company_id)
    }, {
    cacheTime: 0,
    refetchOnWindowFocus: false
  })

  const refreshChart = useCallback(() => {
    if (!chartRef.current || isLoading) {
      return
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(data || []))
    if (chart) {
      chart.render()
    }

    return chart
  }, [data, isLoading])


  useEffect(() => {
    const chart = refreshChart()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [refreshChart])

  return (
    <div className={`card card-flush ${className}`}>
      <div
        className='card-body d-flex flex-column justify-content-between bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
        style={{
          backgroundPosition: '100% 50%',
        }}
      >

        {isLoading ? null : <div ref={chartRef} style={{ height: '350px', color: '#5b41f1', }} />}

      </div>
    </div>
  )
}
export { EngageChart }
