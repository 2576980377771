// @ts-nocheck
import {Column} from 'react-table'
import {DocumentsInfoCell} from './DocumentsInfoCell'
import {DocumentsStatusCell} from './DocumentsStatusCell'
// import {UserSelectionCell} from './UserSelectionCell'
import {DocumentsCustomHeader} from './DocumentsCustomHeader'
// import {UserSelectionHeader} from './UserSelectionHeader'
import {Documents} from '../../core/_models'
import { DocumentsActionsCell } from './DocumentsActionsCell'
import { DocumentsGenericCell } from './DocumentsGenericCell'

const documentsColumns: ReadonlyArray<Column<Documents>> = [
  // {
  //   Header: (props) => <UserSelectionHeader tableProps={props} />,
  //   id: 'selection',
  //   Cell: ({...props}) => <UserSelectionCell id={props.data[props.row.index].id} />,
  // },
  {
    Header: (props) => (
      <DocumentsCustomHeader tableProps={props} title='Id' className='min-w-25px' />
    ),
    id: 'id',
    Cell: ({...props}) => <DocumentsGenericCell labelName={props.data[props.row.index].id} />,
  },
  {
    Header: (props) => (
      <DocumentsCustomHeader tableProps={props} title='Nome' className='min-w-125px' />
    ),
    id: 'name',
    Cell: ({...props}) => <DocumentsInfoCell documents={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <DocumentsCustomHeader tableProps={props} title='Status' className='min-w-90px' />
    ),
    id: 'status_name',
    Cell: ({...props}) => <DocumentsStatusCell documents={props.data[props.row.index]} />,
  },
  {
    Header: (props) => (
      <DocumentsCustomHeader tableProps={props} title='Criada em' className='min-w-90px' />
    ),
    id: 'created_at',
    Cell: ({...props}) => <DocumentsGenericCell labelName={props.data[props.row.index].created_at} />,
  },
  {
    Header: (props) => (
      <DocumentsCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => (
      <DocumentsActionsCell
        campaign={props.data[props.row.index]}
      />
    ),
  },
]

export {documentsColumns}
