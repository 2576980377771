import {FC, useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {imageMeetingsPath, isNotEmpty} from '../../../../../../_metronic/helpers'
import {Campaign} from '../core/_models'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import {useListView} from '../core/ListViewProvider'
import {DocumentsListLoading} from '../components/loading/DocumentsListLoading'
import {create, update, uploadFile} from '../core/_requests'
import {useQueryResponse} from '../core/QueryResponseProvider'
import {useAuth} from '../../../../../../app/modules/auth'

type Props = {
  isLoading: boolean
  response: any
  status: any
}

const editDocumentsSchema = Yup.object().shape({
  
})

const EditModalForm: FC<Props> = ({response, isLoading}) => {
  const documents = response.length > 0 ? response[0] : response
  const {setItemIdForUpdate} = useListView()
  const {refetch} = useQueryResponse()
  const blankImg = '/media/icons/duotune/general/gen046.svg'
  const {currentUser} = useAuth();
  const company_id = (currentUser?.company_id === undefined) ? 0 : currentUser?.company_id;

  const [documentsForEdit] = useState<Campaign>({
    ...documents,
  })

  const cancel = (withRefresh?: boolean) => {
    if (withRefresh) {
      refetch()
    }
    setItemIdForUpdate(undefined)
  }

  const formik = useFormik({
    initialValues: documentsForEdit,
    validationSchema: editDocumentsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      setSubmitting(true)
      try {
        if (isNotEmpty(values.id)) {
          await update(values)
          toast.success(`News ${values.name} atualizado com sucesso`)
        } else {
          await create(values, +company_id)
          toast.success(`News ${values.name} criado com sucesso`)
        }
      } catch (ex) {
        console.error(ex)
      } finally {
        setSubmitting(true)
        cancel(true)
      }
    },
  })

  const changeImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const newImage = await uploadFile(event.target.files![0])
    if (newImage?.data?.file_name) {
      formik.setFieldValue(event.target.name, newImage?.data?.file_name)
    }
  }

  const saveCSV = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) return
    const csv = await uploadFile(event.target.files![0])
    if (csv?.data?.file_name) {
      formik.setFieldValue(event.target.name, csv?.data?.file_name)
    }
  }
 
  const thumbImage = imageMeetingsPath('https://ezmessage.s3.amazonaws.com/customers-uploads', formik.values.attachment_url)
  const csvLabel = formik.values.contacs_url;


  return (
    <>
      <form id='kt_modal_add_user_form' className='form' onSubmit={formik.handleSubmit} noValidate>
        {/* begin::Scroll */}
        <div
          className='d-flex flex-column scroll-y ps-1'
          id='kt_modal_add_user_scroll'
          data-kt-scroll='true'
          data-kt-scroll-activate='{default: false, lg: true}'
          data-kt-scroll-max-height='auto'
          data-kt-scroll-dependencies='#kt_modal_add_user_header'
          data-kt-scroll-wrappers='#kt_modal_add_user_scroll'
          data-kt-scroll-offset='300px'
        >
          <div className='fv-row mb-7'>
            <label className='required fw-bold fs-6 mb-2'>Nome</label>
            <input
              placeholder='Título que será exibido para os usuários'
              {...formik.getFieldProps('name')}
              type='text'
              name='name'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.name && formik.errors.name},
                {
                  'is-valid': formik.touched.name && !formik.errors.name,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
            />
            {formik.touched.name && formik.errors.name && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.name}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='fv-row mb-7'>
            {/* begin::Label */}
            <label className='required fw-bold fs-6 mb-2'>Mensagem</label>
            {/* end::Label */}

            {/* begin::Input */}
            <textarea
              placeholder='Descrição que será exibida para os usuários'
              {...formik.getFieldProps('message')}
              rows={5}
              name='message'
              className={clsx(
                'form-control form-control-solid mb-3 mb-lg-0',
                {'is-invalid': formik.touched.message && formik.errors.message},
                {
                  'is-valid': formik.touched.message && !formik.errors.message,
                }
              )}
              autoComplete='off'
              disabled={formik.isSubmitting || isLoading}
              />
            {formik.touched.message && formik.errors.message && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.message}</span>
                </div>
              </div>
            )}
            {/* end::Input */}
          </div>
          <div className='row mb-7 m-0 p-0'>
            {/* begin::Input group Cover Image */}
            <div className='col-sm-6 ps-0'>
              {/* begin::Label */}
              <label className='d-block fw-bold fs-6 mb-5'>Arquivo .csv com os contatos</label>
              {/* end::Label */}

              {/* begin::Image input */}
              <div
                className='image-input image-input-outline ps-5'
                data-kt-image-input='true'
                style={{backgroundImage: `url('${blankImg}')`}}
              >
                {/* begin::Preview existing image */}
                <div className='form-text'>{csvLabel}</div>
                {/* end::Preview existing image */}

                {/* begin::Label */}
                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title='Escolher arquivo .csv'
                >
                  <i className='bi bi-pencil-fill fs-7'></i>

                  <input
                    type='file'
                    name='contacs_url'
                    accept='.csv'
                    onChange={(event) => saveCSV(event)}
                  />
                </label>
              </div>
            </div>
          </div>
          <div className='row mb-7 m-0 p-0'>
            {/* begin::Input group Cover Image */}
            <div className='col-sm-6 ps-0'>
              {/* begin::Label */}
              <label className='d-block fw-bold fs-6 mb-5'>Imagem a ser enviada na mensagem</label>
              {/* end::Label */}

              {/* begin::Image input */}
              <div
                className='image-input image-input-outline ps-5'
                data-kt-image-input='true'
                style={{backgroundImage: `url('${blankImg}')`}}
              >
                {/* begin::Preview existing image */}
                <div
                  className='image-input-wrapper w-250px h-125px'
                  style={{
                    backgroundPosition: 'center',
                    backgroundSize: 'contain',
                    backgroundImage: `url('${thumbImage}')`,
                  }}
                ></div>
                {/* end::Preview existing image */}

                {/* begin::Label */}
                <label
                  className='btn btn-icon btn-circle btn-active-color-primary w-25px h-25px bg-body shadow'
                  data-kt-image-input-action='change'
                  data-bs-toggle='tooltip'
                  title='Mudar imagem'
                >
                  <i className='bi bi-pencil-fill fs-7'></i>

                  <input
                    type='file'
                    name='attachment_url'
                    accept='.png, .jpg, .jpeg'
                    onChange={(event) => changeImage(event)}
                  />
                  <input type='hidden' name='image_thumb_hidden' />
                </label>
              </div>
              {/* begin::Hint */}
              <div className='form-text'>Tipos permitidos: png, jpg, jpeg.</div>
              {/* end::Hint */}
            </div>
          </div>
        </div>
        <div className='text-center pt-15'>
          <button
            type='reset'
            onClick={() => cancel()}
            className='btn btn-light me-3'
            data-kt-users-modal-action='cancel'
            disabled={formik.isSubmitting || isLoading}
          >
            Cancelar
          </button>

          <button
            type='submit'
            className='btn btn-primary'
            data-kt-users-modal-action='submit'
            disabled={isLoading || formik.isSubmitting || !formik.isValid || !formik.touched}
          >
            <span className='indicator-label'>Confirmar</span>
            {(formik.isSubmitting || isLoading) && (
              <span className='indicator-progress'>
                Por favor, aguarde...{' '}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Actions */}
      </form>
      {(formik.isSubmitting || isLoading) && <DocumentsListLoading />}
    </>
  )
}

export {EditModalForm}
